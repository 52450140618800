export default class Header {

  constructor() {
    this.html = document.querySelector('html');
    this.headerLogo = document.querySelector('.header-logo');
    this.lastScrollTop = 0
    this.threshold = 25
    this.thresholdColor = 100

    window.addEventListener('scroll', (event) => {
      this.scrollHandler();
    });
  }

  scrollHandler() {
    this.headerLogo.classList.toggle('background-header-logo', this.html.scrollTop > this.thresholdColor);

    // scrolling up
    if (this.html.scrollTop > this.threshold && this.lastScrollTop < this.html.scrollTop) {
      this.headerLogo.classList.add('-translate-y-[100px]');
    }

    // scrolling down
    if (this.lastScrollTop > this.html.scrollTop) {
      this.headerLogo.classList.remove('-translate-y-[100px]');
    }

    // save scroll position
    this.lastScrollTop = this.html.scrollTop
  }

}
