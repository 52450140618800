import van11yAccessibleTabPanelAria from "van11y-accessible-tab-panel-aria";
import ShowMore from "./components/show-more";
import LinkNextElement from "./components/link-next-element";

import Header from "./components/header";
import NavMobile from "./components/nav-mobile";
import Jumpmenu from "./components/jumpmenu";
import VantageAccordion from "./components/accordion";

// https://kenwheeler.github.io/slick/

new Header();
new NavMobile();
new Jumpmenu('.js--jumpmenu');
new VantageAccordion('.js--accordion');

document.documentElement.classList.replace('no-js', 'js');
