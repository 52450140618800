import Accordion from 'accordion-js';

export default class FvkkAccordion {

  constructor(selector) {
    this.accordionWrappers = document.querySelectorAll(selector);
    if (!this.accordionWrappers.length) {
      return;
    }

    for (let i = 0; i < this.accordionWrappers.length; ++i) {
      if (this.accordionWrappers[i].dataset.accordionInitialized) { return }
      this.accordionWrappers[i].dataset.accordionInitialized = true;

      new Accordion('#' + this.accordionWrappers[i].id, {
        duration: 500,
        // showItem: true,
        // onToggle: function (currentElement, allElements) {
        //   setTimeout(() => {

        //   }, 600)
        // }
      });
    }

  }

}
