import animateScrollTo from 'animated-scroll-to';

class LinkNextElement extends HTMLElement {

  constructor() {
    super();
    this.parentFold = this.closest('.fold');


    if (this.parentFold) {
      this.addEventListener('click', (event) => {
        event.preventDefault();
        let nextFold = document.querySelector('#' + this.parentFold.id + ' ~ .fold')

        if (nextFold) {
          const _options = {
            speed: 500,
            minDuration: 250,
            maxDuration: 750,
            verticalOffset: 50
          };

          animateScrollTo(nextFold, _options);
        }
      });


      window.addEventListener('scroll', (event) => {
        if (window.scrollY > 80) {
          this.classList.add('hidden');
        }
        else {
          if (window.innerHeight < (this.parentFold.clientHeight + 100)) {
            this.classList.remove('hidden');
          }
        }
      });


      window.addEventListener('resize', (event) => {
        if (window.innerHeight > (this.parentFold.clientHeight + 100)) {
          this.classList.add('hidden');
        }
        else {
          this.classList.remove('hidden');
        }
      });

      window.dispatchEvent(new Event('scroll'));
      window.dispatchEvent(new Event('resize'));
    }
  }


}

// Define the new web component
if ('customElements' in window) {
  customElements.define('link-next-element', LinkNextElement);
}
