export default class NavMobile {

  constructor() {
    this.html = document.querySelector('html');
    this.body = document.querySelector('body');

    if (this.body.dataset.navMobileInitialized) {
      return;
    }
    this.body.dataset.navMobileInitialized = true;
    this.triggerButton = document.querySelector('.navigation--trigger');
    this.triggerLevel2 = document.querySelectorAll('.navigation--level-2-trigger');
    this.trigger = document.querySelector('.navigation--trigger-overlay');
    this.headerInner = document.querySelector('.header-inner');
    this.active = false;

    if (this.headerInner && this.trigger) {
      this.body.addEventListener('click', (event) => {
        if (this.active) {
          setTimeout(() => {
            this.html.classList.remove('navigation__active');
            this.body.classList.add('overflow-x-hidden');
            this.headerInner.classList.replace('translate-x-none', 'translate-x-[100vw]');
          }, 10)
          setTimeout(() => {
            this.headerInner.classList.replace('w-full', 'w-0');
          }, 300);
          this.active = false;
        }
        else {
          let triggerHit = false
          if (event.target == this.trigger) {
            triggerHit = true;
          }

          if (triggerHit) {
            this.active = true;
            this.html.classList.add('navigation__active');
            this.body.classList.remove('overflow-x-hidden');
            this.headerInner.classList.replace('w-0', 'w-full');
            this.headerInner.classList.replace('translate-x-[100vw]', 'translate-x-none');
          }
        }

        this.triggerButton.setAttribute('aria-pressed', this.active);
        this.triggerButton.setAttribute('aria-label', this.active ? 'Navigation ausblenden' : 'Navigation einblenden');
        this.headerInner.setAttribute('aria-hidden', !this.active);
      });


      this.triggerLevel2.forEach((element, index) => {
        element.addEventListener('click', (event) => {
          event.preventDefault();
          event.stopPropagation();

          // document.querySelectorAll('.navigation--header-item').forEach((element, index) => {
          //   element.classList.remove('open');
          // })
          // element.closest('.navigation--header-item').classList.toggle('open');


          document.querySelectorAll('.navigation--header-item.open').forEach((newelement, index) => {
            if (newelement != element.closest('.navigation--header-item')) {
              newelement.classList.remove('open');
            }
          });
          element.closest('.navigation--header-item').classList.toggle('open');
        });
      });

    }
  }

}
