class ShowMore extends HTMLElement {

  constructor() {
    super();

    this.toggle = this.querySelector('.expand-toggle');
    this.toggleLabel = this.querySelector('.expand-toggle-label');
    this.content = this.querySelector('.expand-content');

    this.toggle.addEventListener('click', (event) => {
      event.preventDefault()
      this.active = !this.active;
      this.toggleLabel.innerText = (this.active ? this.toggle.dataset.labelLess : this.toggle.dataset.labelMore);
    });
  }

  static get observedAttributes() {
    return ['active'];
  }

  get active() {
    return this.hasAttribute('active');
  }

  set active(val) {
    if (val) {
      this.setAttribute('active', '');
    } else {
      this.removeAttribute('active');
    }
  }

  // Only called for the active attribute due to observedAttributes
  attributeChangedCallback(name, oldValue, newValue) {
    this.classList.toggle('is-active', this.active);

    if (this.active) {
      this.content.removeAttribute('hidden');
      this.content.removeAttribute('aria-hidden');
    } else {
      this.content.setAttribute('hidden', true);
      this.content.setAttribute('aria-hidden', 'true');
    }
  }
}

// Define the new web component
if ('customElements' in window) {
  customElements.define('show-more', ShowMore);
}
